.awesome {
  font-weight: 800;           
  animation: colorchange 8s infinite ease-in-out;
}

.greeting {
  font-weight: 500;           
}

.dot-weight {
  font-weight: 800;
  font-size: 1.3em;          
}
.greeting:not(:hover) {
  animation:none;
  color: #000000;
}

.font2 {
  font-size: 1.3em;
  font-weight: 400;
}


.font-bold {
  font-weight: 500;
}

.logos {
  width: auto;
  height: 45px;
}

.br-remove {
  display: block
}


@media only screen and (max-width: 1300px) {
  .br-remove {
    display: none
  }
}

@keyframes colorchange {
      0% {
        
        color: #001dff;
      }
      
      10% {
        
        color: #b200ff;
      }
      
      20% {
        
        color: #ff00e1;
      }
      
      30% {
        
        color: #b200ff;
      }
      
      40% {
        color: #f2ddff;
      }
      
      50% {
        color: #00ff61;
      }
      
      60% {
        color: #f2ddff;
      }
      
      70% {
        
        color: #d0ff00;
      }
      80% {
     
        color: #f2ddff;
      }
      
      90% {
     
        color: #ff4300;
      }
      
      100% {
        
        color: #f2ddff;
      }
}


@keyframes colorchange2 {
  0% {
    
    color: #f2ddff;
  }
  
  10% {
    
    color: #dba0ff;
  }
  
  20% {
    
    color: #ff2ddf;
  }
  
  30% {
    
    color: #b200ff;
  }
  
  40% {
    color: #f2ddff;
  }
  
  50% {
    color: #00ff61;
  }
  
  60% {
    color: #e7da65;
  }
  
  
  70% {
    
    color: #001dff;
  }
  80% {
 
    color: #f2ddff;
  }
  
  90% {
 
    color: #ff4300;
  }
  
  100% {
    
    color: #f2ddff;
  }
}

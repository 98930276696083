
.card {
  /* box-shadow: 0px 0px 10px 1px #00000015;
  border-radius: 5px;  */
  border-color: #ffffff;
  /* height: 100%; */
}

/* .card-bottom {
  height: 100%;
  display: flex; 
  align-items: flex-end; */

.test {
  width:25%;
  opacity: 0.4;
}

@media only screen and (max-width: 600px) {
  .test {
    width:60%;
  }
}


.card:hover img {
  opacity: 1;
}

.title {
  font-weight: 600;
  font-size: 1.2em;
}



.content {
  font-size: 1em;
}

.link {
  color: rgba(0,0,0,0.7);
  text-decoration: none; 
}

.link-decor:hover {
  color: #8349FF;
  text-decoration: underline;
  text-decoration-thickness: 4px;
  text-decoration-color: #8349FF;
  text-underline-offset: 2px;
}

a {
  text-decoration: none;
}

.arrow {
  border: solid #00000040;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2.5px;
  margin-bottom: 1px;
  margin-left: 2px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}